<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue"

import { DialogDescription, type DialogDescriptionProps, useForwardProps } from "radix-vue"

const props = defineProps<DialogDescriptionProps & { class?: HTMLAttributes["class"]; unsetAll?: boolean }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <DialogDescription
    v-bind="forwardedProps"
    :class="cn(!unsetAll && 'text-muted-foreground text-sm', props.class)"
  >
    <slot />
  </DialogDescription>
</template>
